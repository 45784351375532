import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import axios from "axios";

function MembershipSubscription(props) {
    // console.log(props);    
    const [memData, setMemData] = useState(props.currentMember);
    const [giftApplyMsg, setGiftApplyMsg] = useState('');
    const [giftCard, setGiftCard] = useState(false);
    const [voucher, setVoucher] = useState('');
    const [giftAmount, setGiftAmount] = useState(0);
    const [planAmount, setPlanAmount] = useState(0);
    const [paymentRequest, setPaymentRequest] = useState({
        amount: 0,
        paymentDescriptor: null,
        termPlan: 0,
        gcid: 0,
        memberID: memData.memberID
    });
    const btnLists = [
        { id: 1, title: '1 Month Plan', plan: 1, rate: 9.95 },
        { id: 2, title: '6 Months Plan', plan: 6, rate: 45.95 },
        { id: 3, title: '1 Year Plan', plan: 12, rate: 99.95 }
    ];
    const [selected, setSelected] = useState(0);

    const handleMembershipPlan = (row) => {
        setSelected(row.id);
        setPlanAmount(row.rate);
        setPaymentRequest({ ...paymentRequest, amount: parseFloat(calulateTotal(row.rate, giftAmount)), termPlan: row.plan, paymentDescriptor: 'Plan ' + row.plan + 'm' });
    };

    const handleGiftCheck = (e) => {
        setGiftCard(e.target.checked);
        setVoucher('');
        setGiftAmount(0);
        setGiftApplyMsg('');
        setPaymentRequest({ ...paymentRequest, amount: parseFloat(calulateTotal(planAmount, 0)), gcid: 0 });
    }

    const handleGiftApply = () => {
        try {
            if (planAmount > 0) {
                if (voucher) {
                    let msg = '';
                    axios
                        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/GiftCards/GetByCode/` + voucher)
                        .then((response) => {
                            if (response.data.gcid > 0) {
                                msg = 'Applied successfully';
                            } else {
                                msg = 'Invalid';
                            }
                            setGiftApplyMsg(msg);
                            setGiftAmount(response.data.giftAmount);
                            setPaymentRequest({ ...paymentRequest, amount: parseFloat(calulateTotal(planAmount, response.data.giftAmount)), gcid: response.data.gcid });
                        });
                } else {
                    setGiftApplyMsg('Enter voucher');
                    setGiftAmount(0);
                    setPaymentRequest({ ...paymentRequest, amount: parseFloat(calulateTotal(planAmount, 0)), gcid: 0 });
                }
            } else {
                setGiftApplyMsg('First select a membership plan & then apply voucher');
            }
        }
        catch (ex) {
            setGiftApplyMsg('Error...');
            setGiftAmount(0);
            setPaymentRequest({ ...paymentRequest, amount: parseFloat(calulateTotal(planAmount, 0)), gcid: 0 });
        }
    }

    const handlePay = () => {
        // console.log(paymentRequest);
        if (paymentRequest.memberID > 0) {
            if (planAmount > 0) {
                if (paymentRequest.amount >= 0.5) {
                    if (memData.callFrom === 'registration') {
                        props.history.push({
                            pathname: '/member-card-info-new',
                            state: paymentRequest
                        });
                    } else if (memData.callFrom === 'member') {                        
                        props.history.push({
                            pathname: '/member/member-card-info',
                            state: paymentRequest
                        });
                    } else {
                        props.history.push('/member-login');
                    }
                } else {
                    // alert('Payment amount can't be less than $0.5');                    
                }
            } else {
                alert('Select a membership plan.');
            }
        } else {
            props.history.push('/member-login');
        }
    };

    const calulateTotal = (pAmt, gAmt) => {
        let pAmount = parseFloat(pAmt);
        let gAmount = parseFloat(gAmt);
        if (pAmount > gAmount)
            return (pAmount - gAmount).toFixed(2);
        else
            return 0;
    };

    return (
        <div>
            <div class="form-group">
                <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Membership Subscription</h3>
            </div>
            {btnLists.map((item) => (
                <div class="col-md-12 form-group">
                    <button key={item.id} onClick={() => handleMembershipPlan(item)}
                        class={item.id === selected ? 'member-card member-card-active' : 'member-card'}
                    >
                        {item.title}<span style={{ float: "right" }}>${item.rate}</span>
                    </button>
                </div>
            ))}
            <div class="col-md-12 form-group" style={{ marginBottom: '20px' }}>
                <CheckBoxComponent checked={giftCard} label='Add Gift Card' onChange={(e) => handleGiftCheck(e)} />
            </div>
            {giftCard &&
                <>
                    <div class="col-md-8 form-group">
                        <TextBoxComponent cssClass="e-outline input-border" placeholder="Voucher Number" onChange={(e) => { setVoucher(e.target.value) }} />
                        {/* <span>You can use maximum one gift card per transaction.</span> */}
                        <div style={{ display: 'block', width: '100%', clear: 'both' }}>
                            <span style={{ color: 'red' }}>{giftApplyMsg}</span>
                        </div>
                    </div>
                    <div class="col-md-4 form-group">
                        <button className="btn btn-block btn-primary" style={{ padding: '10px', margin: '0' }} onClick={handleGiftApply}>APPLY</button>
                    </div>
                </>
            }
            <div class="col-md-12 form-group">
                <button className="btn btn-block btn-primary" onClick={handlePay}>
                    <>
                        {planAmount > 0 ? (
                            "Pay $" + paymentRequest.amount
                        ) : (
                            "Pay"
                        )}
                    </>
                </button>
            </div>
        </div>
    )
}

export default withRouter(MembershipSubscription);