import React, { useState, useEffect } from 'react';
import { useLocation, withRouter, Link } from 'react-router-dom';
import { Fetch } from "../services/dataservice";
import validator from 'validator';
import CMSMenu from './CMSMenu';

function PoliceRegistrationFuture({ history }) {
    const location = useLocation();
    const [isUpdated, setIsUpdated] = useState(false);
    const [memberData, setMemberData] = useState({
        memberID: 0,
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        phone: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        latitude: 0,
        longitude: 0,
        homeType: null,
        multiLevel: false,
        levelNumber: null,
        fastestAccess: null,
        roomNumber: null,
        bestAccessToEnter: null,
        referredBy: null,
        buildingType: null,
        constructionType: null,
        numberOfStories: null,
        disabilities: false,
        healthConditions: null,
        careTakerPhone: null,
        careTakerName: null,
        caregiver: false,
        policeComment: null,
        pets: [],
        emergencyContacts: []
    });

    useEffect(() => {
        if (location.state === undefined) {
            history.push('/member-registration');
        } else {
            memberData.email = location.state.email;
            memberData.password = location.state.password;
            memberData.firstName = location.state.firstName;
            memberData.lastName = location.state.lastName;
            memberData.dateOfBirth = location.state.dateOfBirth;
            memberData.phone = location.state.phone;
            memberData.address = location.state.address;
            memberData.city = location.state.city;
            memberData.state = location.state.state;
            memberData.zip = location.state.zip;
            memberData.homeType = location.state.homeType;
            memberData.multiLevel = location.state.multiLevel;
            memberData.levelNumber = location.state.levelNumber;
            memberData.fastestAccess = location.state.fastestAccess;
            memberData.roomNumber = location.state.roomNumber;
            // memberData.bestAccessToEnter = location.state.bestAccessToEnter;
            if (location.state.referredBy) {
                memberData.referredBy = location.state.referredBy.trim();
            } else {
                memberData.referredBy = '';
            }                                   
            memberData.buildingType = location.state.buildingType;
            memberData.constructionType = location.state.constructionType;
            memberData.numberOfStories = location.state.numberOfStories;
            memberData.disabilities = location.state.disabilities;
            memberData.healthConditions = location.state.healthConditions;
            memberData.careTakerPhone = location.state.careTakerPhone;
            memberData.careTakerName = location.state.careTakerName;
            memberData.caregiver = location.state.caregiver;
            memberData.pets = location.state.pets;
            memberData.emergencyContacts = location.state.emergencyContacts;
        }
    }, []);

    const handleChange = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value });
    };
    
    const handleProfilePolice = () => {
        try {
            if (memberData) {
                setIsUpdated(true);

                // check for 18 years                
                let age = 0;
                if (isNaN(memberData.dateOfBirth.getTime())) {
                    age = 18;
                } else {
                    const currDate = new Date();
                    let diffInTime = currDate.getTime() - memberData.dateOfBirth.getTime();
                    let diffInHours = diffInTime / (1000 * 60 * 60);
                    age = parseInt(diffInHours / 8766);
                }

                if (age < 18) {
                    // Save Member info in DB
                    // .then() is used to handle asynchronous operation that hasn’t been completed yet(issue solved for: Promise {<pending>})
                    saveMemberData(memberData).then((memberId) => {
                        if (memberId > 0) {                
                            alert('You have successfully registered. Thank you!');
                            history.push('/member-login');                             
                        } else {
                            alert('Error on member registration.');
                            history.push('/member-registration');
                        }
                    });                             
                } else {                   
                    if (memberData.referredBy && (memberData.referredBy.toLowerCase() === 'war' || memberData.referredBy.toLowerCase() === 'wayne allen root')) {
                        alert('You will receive 60 days FREE subscription. Please enter your credit card information. After 60 days, your credit card will be charged.\nThank you!');
                    } else {
                        alert('You will receive 30 days FREE subscription. Please enter your credit card information. After 30 days, your credit card will be charged.\nThank you!');
                    }
                        
                    history.push({
                        pathname: '/member-payment-future',
                        state: memberData
                    });                    
                }
            } else {
                alert('Unexpected error, please try again after some time.');
                history.push('/member-registration');    
            }
        }
        catch (ex) {
            alert('Error on registration.');
            setIsUpdated(false);
        }
    }
    
    const saveMemberData = async (memberData) => {
        let mId = await Fetch(`/Member/Add`, "post", memberData);
        if (mId > 0) {
            // pets at location
            if (Object.keys(memberData.pets).length > 0) {
                memberData.pets.forEach((item) => {
                    item.memberID = mId;
                })

                let resPet = await Fetch(`/Member/AddPet/` + mId, "post", memberData.pets);
            }

            // emergency contacts                
            if (memberData.emergencyContacts.length > 0) {
                let unmaskPhone = '';
                memberData.emergencyContacts.forEach((item) => {
                    item.memberID = mId;
                    unmaskPhone = item.phone.replace(/[^0-9]+/g, '');
                    item.phone = unmaskPhone;
                    if (!validator.isEmail(item.email)) {
                        item.email = '';
                    }
                })

                let resEContact = await Fetch(`/Member/AddEmergencyContact/` + mId, "post", memberData.emergencyContacts);
            }
        }        
        return mId;
    }

    return (        
        <div>
            <div style={{ float: "left" }}>
                <div class="int-container desktop">
                    <img src="/assets/img/int-bg.jpg" alt="" />
                </div>
                <div class="int-container mobile">
                    <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
                </div>
                <div class="main-container">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6 text-right">
                                <CMSMenu />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 text-center">
                            </div>
                            <div class="col-md-7">
                                <div class="form-container">
                                    <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                    <div class="form-group">
                                        <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Police</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-group">
                                            <label>Comments</label>
                                            <textarea className='form-control' style={{ height: '70px' }} value={memberData.policeComment} onChange={(e) => handleChange(e, "policeComment")} />
                                        </div>                                        
                                        <div class="col-md-12 form-group">
                                            {!isUpdated ?
                                                <button className="btn btn-block btn-primary" onClick={handleProfilePolice}>Submit</button>
                                                :
                                                <p className="btn btn-block btn-primary" style={{ cursor: 'auto', padding: '15px 30px' }}><i class="fa fa-spinner fa-spin" style={{ fontSize: '30px', color: 'white' }}></i></p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    )
}

export default withRouter(PoliceRegistrationFuture);