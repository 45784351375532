import React, { useState, useEffect, createContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './Stripe.css';
import CheckoutFormFuture from './CheckoutFormFuture';
import { Fetch } from "../services/dataservice";

export const PaymentContext = createContext(null);

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

function CardDetailsFuture(props) {
    const [memberData, setMemberData] = useState(props.currentPayment); 
    const [setupIntentRequest, setSetupIntentRequest] = useState({
        name: props.currentPayment.firstName + ' ' + props.currentPayment.lastName,
        email: props.currentPayment.email,
        referredBy: props.currentPayment.referredBy,
        termPlan: props.currentPayment.termPlan,
        setupDescription: props.currentPayment.setupDescription               
    });
    const [csMessage, setCSMessage] = useState(null);
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        // Create Setup Intent as soon as the page loads
        (async () => {
            const response = await Fetch(`/Payment/CreateSetupIntent`, "post", setupIntentRequest);           
            setClientSecret(response.client_secret);
            if (!response.client_secret) {
                setCSMessage('An unexpected error occurred.');
            }
        })();

        return () => {
            // this now gets called when the component unmounts            
        };
    }, []);

    const appearance = {
        theme: 'stripe',    // 'night'
        labels: 'floating'
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <PaymentContext.Provider value={memberData}>
            <div>
                <div class="form-group">
                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Card Details</h3>
                </div>
                <div class="col-md-12 form-group" style={{ color: '#fff', fontSize: '18px' }}>
                    Amount: <span style={{ color: '#EF5030' }}>$0</span>
                </div>
                <div class="col-md-12 form-group">
                    {clientSecret ? (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutFormFuture />
                            {/* for testing card no.
                            4242424242424242 Payment succeeds
                            4000002500003155 Payment requires authentication
                            4000000000009995 Payment is declined for insufficient funds */}
                        </Elements>
                    ) : (
                        <>                        
                            <p style={{ marginTop: '10px', marginLeft: '275px' }}><i class="fa fa-spinner fa-spin" style={{ fontSize: '40px', color: '#fff' }}></i></p>
                            <div id="payment-message">{csMessage}</div>
                        </>
                    )}
                </div>
            </div>
        </PaymentContext.Provider>
    )
}

export default CardDetailsFuture;