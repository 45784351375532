import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { Fetch } from "../services/dataservice";

const RetrievePayment = ({ history }) => {
  const stripe = useStripe();  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [transaction, setTransaction] = useState({
    paymentId: null,
    status: null,
    amount: null,
    currency: null,        
    email: null,
    paymentMethod: null,
    paymentMethodType: null
  });
  const [paymentResult, setPaymentResult] = useState({
    paymentID: null,
    memberID: null,
    gcid: null,
    termPlan: null
  });

  const domainName = process.env.REACT_APP_FRONTEND_BASE_URL;
  const previousPageUrl = document.referrer;    // return the URL of the page that linked to the current page
  const [pageName, setPageName] = useState(previousPageUrl.replace(domainName, ''));  

  useEffect(() => {
    // prevent to refresh the payment-status page
    if (pageName !== '/member-card-info-new' && pageName !== '/member/member-card-info') {
      history.push('/member-login');
    }    

    if (!stripe) {      
      return;
    }
    
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      setMessage('An error occurred while processing your request. Please contact support.');
      setIsLoading(false);
      return;
    }
    
    const queryParams = new URLSearchParams(window.location.search);
    paymentResult.memberID = queryParams.get('mid');
    paymentResult.gcid = queryParams.get('gid');
    paymentResult.termPlan = queryParams.get('tp');

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // console.log(paymentIntent);
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Success! Payment received.');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Payment failed. Please try another payment method.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }      

      setTransaction({
        ...transaction,
        paymentId: paymentIntent.id,
        status: paymentIntent.status,
        amount: paymentIntent.amount,
        currency: paymentIntent.currency,        
        email: paymentIntent.receipt_email,
        paymentMethod: paymentIntent.payment_method,
        paymentMethodType: paymentIntent.payment_method_types[0]
      });

      paymentResult.paymentID = paymentIntent.id;

      // Save Payment Info      
      (async () => {
        if (paymentResult.paymentID) {
          const response = await Fetch(`/Payment/Retrieve`, "post", paymentResult);
        }        
      })();

      setIsLoading(false);
    })
    .catch((err) => {
      // console.log(err);
      setMessage('An error occurred while processing your request.');
      setIsLoading(false);
    });

    return () => {
      // this now gets called when the component unmounts                 
    };
  }, [stripe]);

  return (
    <div>
      {isLoading ? 
        <p style={{ marginTop: '100px' }}><i class="fa fa-spinner fa-spin" style={{ fontSize: '48px', color: 'white' }}></i></p>                            
        : 
        (
          <>
            {transaction.status === 'succeeded' ? 
              (
                <>
                  <i class="fa fa-check" aria-hidden="true" style={{ fontSize: '40px', color: 'green' }}></i>
                  <h5 style={{ color: "#fff", width: "100%", textAlign: "center", textTransform: 'none' }}>
                    Thank you for your payment!
                  </h5>
                  <h6 style={{ color: "#fff", width: "100%", textAlign: "center", textTransform: 'none', fontWeight: 'normal' }}>
                    A receipt for this transaction has been sent via email for your records.
                  </h6>
                  <br />
                  <p style={{ color: "#c7c1c1", fontSize: '15px', margin: '0' }}>Total Payment Amount</p>
                  <p style={{ color: "#fff", fontSize: '20px' }}> ${transaction.amount / 100}</p>
                  <p style={{ fontSize: '14px' }}>Payment Id: <span style={{ color: "#fff" }}>{transaction.paymentId}</span></p>
                </>
              ) 
              :
              (
                <>
                  <i class="fa fa-exclamation-circle" aria-hidden="true" style={{ fontSize: '40px', color: '#ef5030' }}></i>
                  <p></p>
                  <br/>
                </>
              )
            }

            {message}
          </>
        )
      }
    </div>
  );
};

export default withRouter(RetrievePayment);