
import { Route, Switch } from 'react-router-dom';
import './App.css';
import './Component/Syncfusion.css';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Error from './Pages/Error';
import Footer from './Component/Footer';
import MemberLogin from './Component/MemberLogin';
import Signin from './Component/Signin';
import ForgotPassword from './Component/ForgotPassword';
import MemberRegistration from './Component/MemberRegistration';
import UpdateProfileNew from './Component/UpdateProfileNew';
import FireRegistration from './Component/FireRegistration';
import MedicalRegistration from './Component/MedicalRegistration';
import PoliceRegistration from './Component/PoliceRegistration';
import PoliceRegistrationFuture from './Component/PoliceRegistrationFuture';
import Info911 from './Component/Info911';
import VerifyRequest from './Component/VerifyRequest';
import OptOut from './Component/OptOut';
import MemberPaymentNew from './Component/MemberPaymentNew';
import MemberPaymentFuture from './Component/MemberPaymentFuture';
import MemberCardInfoNew from './Component/MemberCardInfoNew';
import MemberCardInfoFuture from './Component/MemberCardInfoFuture';
import PaymentStatus from './Component/PaymentStatus';
import PaymentStatusFuture from './Component/PaymentStatusFuture';
import DeviceDetect from './Component/DeviceDetect';
import Member from './Component/Member';
import Admin from './Component/Admin/Admin';
// import Location from './Component/Location';
// import LocationAPI from './Component/LocationAPI';
import LocationMap from './Component/LocationMap';
import Valid from './Component/Valid';

const App = () => {
  return (
    <div>
      <Switch>
        {/* <Route exact path='/' component={Home} /> */}
        <Route exact path='/' component={MemberRegistration} />
        <Route exact path='/about-us' component={AboutUs} />
        <Route exact path='/member-login' component={MemberLogin} />
        <Route exact path='/signin' component={Signin} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/member-registration' component={MemberRegistration} /> 
        <Route exact path='/update-profile-new' component={UpdateProfileNew} />
        <Route exact path='/fire-registration' component={FireRegistration} />
        <Route exact path='/medical-registration' component={MedicalRegistration} />
        <Route exact path='/police-registration' component={PoliceRegistration} />
        <Route exact path='/police-registration-future' component={PoliceRegistrationFuture} />
        <Route exact path='/info-911' component={Info911} />
        <Route exact path='/verify-request' component={VerifyRequest} />
        <Route exact path='/opt-out' component={OptOut} />
        <Route exact path='/member-payment-new' component={MemberPaymentNew} />
        <Route exact path='/member-payment-future' component={MemberPaymentFuture} />
        <Route exact path='/member-card-info-new' component={MemberCardInfoNew} />
        <Route exact path='/member-card-info-future' component={MemberCardInfoFuture} />
        <Route exact path='/payment-status' component={PaymentStatus} />
        <Route exact path='/payment-status-future' component={PaymentStatusFuture} />
        <Route exact path='/device-detect' component={DeviceDetect} />
        <Route path="/member" component={Member} />
        <Route path="/admin" component={Admin} />        
        {/* <Route exact path='/location' component={Location} /> 
        <Route exact path='/location-api' component={LocationAPI} /> */}
        <Route exact path='/location-map' component={LocationMap} />
        <Route exact path='/valid' component={Valid} />
        <Route component={Error} />
      </Switch>
      <Footer />
    </div>
  )
}

export default App;