import React, { useState, useContext } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import axios from "axios";
import { Fetch } from "../services/dataservice";
import validator from 'validator';
import {PaymentContext} from './CardDetailsFuture';

function CheckoutFormFuture() {
  const memberData = useContext(PaymentContext);
  //console.log(memberData);

  const stripe = useStripe();
  const elements = useElements();
  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);      

  const handleStripePay = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }    

    setMessage(null);
    setIsLoading(true);

    // Save member info in DB
    let memberId = 0;
    try {
      if (memberData) {
        memberId = await Fetch(`/Member/Add`, "post", memberData);        
        if (memberId > 0) {
          // pets at location
          if (Object.keys(memberData.pets).length > 0) {
            memberData.pets.forEach((item) => {
              item.memberID = memberId;
            })

            let resPet = await Fetch(`/Member/AddPet/` + memberId, "post", memberData.pets);
          }

          // emergency contacts                
          if (memberData.emergencyContacts.length > 0) {
            let unmaskPhone = '';
            memberData.emergencyContacts.forEach((item) => {
              item.memberID = memberId;
              unmaskPhone = item.phone.replace(/[^0-9]+/g, '');
              item.phone = unmaskPhone;
              if (!validator.isEmail(item.email)) {
                item.email = '';
              }
            })

            let resEContact = await Fetch(`/Member/AddEmergencyContact/` + memberId, "post", memberData.emergencyContacts);
          }

          // After successfully card registration, member will be redirected to `return_url`.
        } else {
          alert('Error on member registration.');
          setIsLoading(false);
          return;            
        }     
      } else {
          alert('Unexpected error, please try again after some time.');
          setIsLoading(false);
          return;    
      }
    }
    catch (ex) {
      alert('Error on registration.');
      setIsLoading(false);
      return;
    }
    // End of save member  

    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element  
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_FRONTEND_BASE_URL}/payment-status-future`,        
      },
    });

    if (error) {
      // If there is an immediate error when confirming the payment.
      setMessage(error.message);

      // Due to a payment error, remove the member information that has been saved from the database.      
      axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/DeleteRegistration/` + memberId);      
    } else {      
      // Customer will be redirected to `return_url`.      
    }

    setIsLoading(false);
  };

  return (
    <div>      
      <PaymentElement id="payment-element" />

      <button id="submit" className="btn btn-block btn-primary" disabled={isLoading || !stripe || !elements} onClick={handleStripePay}>
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please wait...</div>
          ) : (
            "Add card to account"
          )}
        </span>
      </button>
      {message &&
        <div id="payment-message">
          {message}
        </div>
      }
    </div>
  );
}

export default CheckoutFormFuture;