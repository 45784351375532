import React, { useState, useEffect } from 'react';
import { useLocation, withRouter, Link } from 'react-router-dom';
import CardDetailsFuture from './CardDetailsFuture';

const MemberCardInfoFuture = ({ history }) => {
    const location = useLocation();
    const [showChild, setShowChild] = useState(false);
    const [memberData, setMemberData] = useState();
    
    useEffect(() => {
        if (location.state === undefined) {
            history.push('/member-registration');
        } else {
            setMemberData(location.state);
            setShowChild(true);
        }
    }, []);
    
    return (
        <div style={{ float: "left" }}>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="form-container">
                                <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                {showChild &&
                                    <CardDetailsFuture currentPayment={memberData} />
                                }                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MemberCardInfoFuture);