import React, { useState, useEffect } from 'react';
import { useLocation, withRouter, Link } from 'react-router-dom';

const MemberPaymentFuture = ({ history }) => {
    const location = useLocation();
    const [selected, setSelected] = useState(0);    
    const [planAmount, setPlanAmount] = useState(0);
    const [memberData, setMemberData] = useState();       
    const btnLists = [
        { id: 1, title: '1 Month', plan: 1, rate: 9.95 },
        { id: 2, title: '6 Months', plan: 6, rate: 45.95 },
        { id: 3, title: '1 Year', plan: 12, rate: 99.95 }
    ];

    useEffect(() => {
        if (location.state === undefined) {
            history.push('/member-registration');
        } else {
            setMemberData(location.state);            
        }       
    }, []);

    const handleMembershipPlan = (row) => {
        setSelected(row.id);
        setPlanAmount(row.rate);
        setMemberData({ ...memberData, termPlan: row.plan, setupDescription: 'Enroll ' + row.title.toLowerCase() + ' recurring payment plan' });
    };

    const handlePay = () => {        
        if (planAmount > 0) { 
            history.push({
                pathname: '/member-card-info-future',
                state: memberData
            });
        } else {
            alert('Select a membership plan.');
        }        
    };

    return (
        <div style={{ float: "left" }}>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="form-container">
                                <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                <div>
                                    <div class="form-group">
                                        <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Membership Subscription</h3>
                                    </div>
                                    {btnLists.map((item) => (
                                        <div class="col-md-12 form-group">
                                            <button key={item.id} onClick={() => handleMembershipPlan(item)}
                                                class={item.id === selected ? 'member-card member-card-active' : 'member-card'}
                                            >
                                                {item.title}<span style={{ float: "right" }}>${item.rate}</span>
                                            </button>
                                        </div>
                                    ))}
                                    <div class="col-md-12 form-group">
                                        <button className="btn btn-block btn-primary" onClick={handlePay}>Select a plan</button>
                                    </div>
                                </div>                                                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MemberPaymentFuture);