import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { Fetch } from "../services/dataservice";

const RetrievePaymentFuture = ({ history }) => {
  const stripe = useStripe();  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);  
  const [setupResult, setSetupResult] = useState({
    setupID: null,
    status: null
  });

  const domainName = process.env.REACT_APP_FRONTEND_BASE_URL;
  const previousPageUrl = document.referrer;    // return the URL of the page that linked to the current page
  const [pageName, setPageName] = useState(previousPageUrl.replace(domainName, ''));  

  useEffect(() => {
    // prevent to refresh the setup-payment-status page    
    if (pageName !== '/member-card-info-future') {
      history.push('/member-login');
    }    

    if (!stripe) {      
      return;
    }
    
    // Retrieve the "setup_intent_client_secret" query parameter appended to your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );    

    if (!clientSecret) {
      setMessage('An error occurred while processing your request. Please contact support.');
      setIsLoading(false);
      return;
    } 

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      // console.log(setupIntent);
      switch (setupIntent.status) {
        case 'succeeded':
            setMessage('Success! Your payment method has been saved.');
            break;
        case 'processing':
            setMessage("Processing payment details. We'll update you when processing is complete.");
            break;
        case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting payment again
            setMessage('Failed to process payment details. Please try another payment method.');
            break;
        default:
            setMessage('Something went wrong.');
            break;          
      }

      setupResult.setupID = setupIntent.id;
      setupResult.status = setupIntent.status;     
            
      (async () => {
        if (setupResult.setupID) {
          const response = await Fetch(`/Payment/FinishSetupIntent`, "post", setupResult);
        }        
      })();

      setIsLoading(false);
    })
    .catch((err) => {
      // console.log(err);
      setMessage('An error occurred while processing your request.');
      setIsLoading(false);
    });

    return () => {
      // this now gets called when the component unmounts                 
    };
  }, [stripe]);

  return (
    <div>
      {isLoading ? 
        <p style={{ marginTop: '100px' }}><i class="fa fa-spinner fa-spin" style={{ fontSize: '48px', color: 'white' }}></i></p>                            
        : 
        (
          <>
            {setupResult.status === 'succeeded' ? 
              (
                <>
                  <i class="fa fa-check" aria-hidden="true" style={{ fontSize: '40px', color: 'green' }}></i>
                  <h5 style={{ color: "#fff", width: "100%", textAlign: "center", textTransform: 'none' }}>
                    You have successfully registered. Thank you!
                  </h5>
                  <br/>             
                </>
              ) 
              :
              (
                <>
                  <i class="fa fa-exclamation-circle" aria-hidden="true" style={{ fontSize: '40px', color: '#ef5030' }}></i>
                  <p></p>
                  <br/>
                </>
              )
            }

            {message}
          </>
        )
      }
    </div>
  );
};

export default withRouter(RetrievePaymentFuture);